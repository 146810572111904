import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import TermObj1 from '@/assets/img/advocacy-for-children/crc_obj1@2x.png';
import TermObj2 from '@/assets/img/advocacy-for-children/crc_obj2@2x.png';
import TermObj3 from '@/assets/img/advocacy-for-children/crc_obj3@2x.png';
import TermObj4 from '@/assets/img/advocacy-for-children/crc_obj4@2x.png';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import {
  ObjList,
  CardFlex,
  DlBox,
} from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const termData = [
  {
    id: 0,
    img: TermObj1,
    tit: `유니세프가 하는 모든 일의 핵심, 아동권리협약`,
    dec: `아동권리협약은 전 세계 거의 모든 국가가 비준한 유일한 유엔인권조약으로, 전 세계 아동의 삶을 개선하기 위한 도덕적, 정치적, 법적 토대를 제공합니다. 유니세프는 아동권리협약에 기초하여 변하지 않는 윤리 원칙과 국제적 행동 지침으로서 아동의 권리를 가장 우선시합니다.`,
  },
  {
    id: 1,
    img: TermObj2,
    tit: `아동권리협약 홍보`,
    dec: `유니세프는 만 18세 미만 아동과 그들을 둘러싼 모든 사람들이 아동권리협약에 대해 이해하고 실현할 수 있도록 여러 대상에게 다양한 방법으로 홍보합니다. 아동권리협약과 선택의정서의 비준을 장려하고 이행을 지원하여 한국의 법률과 정책을 아동친화적으로 강화할 수 있도록 협력합니다.`,
  },
  {
    id: 2,
    img: TermObj3,
    tit: `아동권리협약 이행 지원`,
    dec: `국가보고서 초안 작성을 위한 주요 협의체, 시민사회 보고서 작성 협의체 등 한국의 아동권리협약 이행을 위한 여러 단계에 참여하여 활동합니다. 아동권리위원회의 최종견해에 따른 국가 전략을 수립하기 위해 협력하기도 합니다.`,
  },
  {
    id: 3,
    img: TermObj4,
    tit: `보고서 작성 지원`,
    dec: `가장 취약하고 관심이 필요한 사람들의 목소리가 아동권리위원회에 전달될 수 있도록 지원합니다. 모든 수준에서 광범위한 협의가 이뤄질 수 있도록 촉진하고, 시민사회가 보고서를 제출할 수 있도록 장려하고 협력합니다. <strong>무엇보다 아동권리협약 보고과정에서 아동이 직접 참여하고 목소리를 전달할 수 있도록 지원합니다.</strong>`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리사업팀`,
    tel: `02-724-8584`,
    email: `crp@unicef.or.kr`,
  },
];

const Card = styled.dl`
  dt {
    small {
      font-weight: 700;
      color: #828385;
      font-size: 14px;
      margin-left: 16px;
      ${breakpoint(640)} {
        margin-left: 0;
      }
    }
  }
  dd {
    margin-top: 16px;
    background: #f8f9fa;
    padding: 32px;
    line-height: 2;

    .blit-list.t2 {
      & > li {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    dt {
      small {
        display: block;
        margin-top: 8px;
        font-size: 12px;
      }
    }
    dd {
      padding: 24px;

      .blit-list.t2 {
        & > li {
          margin-bottom: 24px;
        }
      }
    }
  }
`;

const SectionTerm = styled(Section)`
  ${DlBox} {
    margin-top: 0;
    ul {
      & > li {
        padding-left: 21px;
        position: relative;

        &::before {
          content: '가.';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  ${ObjList} {
    margin-top: 64px;
    & > li {
      display: flex;
      width: 100%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .img-wrap {
      width: 56px;
      text-align: left;
    }
    .dec-wrap {
      text-align: left;
      width: calc(100% - 56px);
      padding-left: 24px;
      margin-top: 0;
      .dec {
        margin-top: 8px;

        strong {
          color: #1cabe2;
        }
      }
    }
  }
  .btn-wrap {
    margin-top: 64px;
    text-align: right;

    ${ButtonMoreView} {
      text-align: left;
      width: 100%;
      max-width: 352px;
    }
  }
  ${breakpoint(`tablet`)} {
    ${ObjList} {
      margin-top: 48px;
      & > li {
        flex-wrap: wrap;
        margin-bottom: 24px;
      }
      .dec-wrap {
        width: 100%;
        padding-left: 0;
        margin-top: 8px;
      }
    }
    .btn-wrap {
      margin-top: 48px;

      ${ButtonMoreView} {
        max-width: 100%;
      }
    }
  }
`;
const SectionPerformance = styled(Section)`
  ${CardFlex} {
    & > li {
      width: 100%;
      margin-top: 88px;

      .card {
        ${Tit} {
          border-radius: 48px;
          left: 32px;
        }
      }
    }
  }
  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .tip {
    margin-top: 8px;
    text-align: right;
    color: #828385;
    font-size: 14px;
    .c-blue {
      color: #1cabe2;
    }
  }

  table {
    th,
    td {
      line-height: 2;
      padding-top: 13px;
      padding-bottom: 13px;
      height: auto;
    }
    thead {
      th {
        word-break: break-all;
      }
    }
    tbody {
      th {
        color: #374ea2;
        font-size: 14px;
        text-align: center;
        background: #f8f9fa;
        word-break: break-all;
      }
      td {
        .link-item {
          color: #1cabe2;
          text-decoration: underline;
        }
      }

      tr:last-child {
        td {
          border-bottom-color: #ddd;
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${CardFlex} {
      & > li {
        margin-top: 70px;

        .card {
          padding-top: 60px;
          padding-bottom: 50px;
          ${Tit} {
            left: 16px;
          }
        }
      }
    }
    article {
      margin-bottom: 72px;
    }
    table {
      th,
      td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .tip {
    font-size: 12px;
  }
`;

const CrcImplementation: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A022'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="아동권리협약 이행"
      description="for every child, every right"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  한국 아동권리 증진을 위해, <br />
                  아동권리협약 이행 모니터링
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                아동권리협약(Convention on the Rights of the Child: CRC)은
                유엔기구인 유니세프에게 협약 이행을 위한 역할을 부여한
                인권조약입니다. 모든 아동을 위해 일하는 유니세프는
                아동권리협약을 널리 알리고 협약에 명시된 모든 아동권리의 실현을
                목표로 활동합니다. 유니세프는 정부가 아동권리협약을 이행하고
                보고하는 과정에서 기술 자문이나 지원 등 전문적인 조언을
                제공합니다. 또한 가장 취약하고 관심이 필요한 사람들의 목소리를
                아동권리위원회에 전달할 수 있도록 지원합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="3yk56eoa498" />
          </PageComponent>
        </Container>
      </Section>

      <SectionTerm className="by-sub-main-layout">
        <Container>
          <DlBox>
            <dt>
              <Tit size="s3" color="sky">
                [유엔아동권리협약 제45조]
              </Tit>
            </dt>
            <dd>
              <p className="dec">
                이 협약의 효과적인 이행을 촉진하고 협약이 다루는 분야에서
                국제협력을 장려하기 위해 <br />
              </p>
              <ul className="dec">
                <li>
                  전문기구,{` `}
                  <strong>
                    유니세프(유엔아동기금)를 비롯한 유엔기구들은 이 협약 중
                    그들의 권한에 속하는 규정 이행과 관련된 논의에 대표를 파견할
                    권리
                  </strong>
                  를 가진다. 위원회는 전문기구,
                  <strong>
                    유니세프 및 위원회가 적절하다고 판단하는 그 밖의 권한 있는
                    기구에 대해 각 기구의 권한에 속하는 분야에 있어 협약 이행에
                    관한 전문적인 자문 제공을 요청
                  </strong>
                  할 수 있다. 위원회는 전문기구,{` `}
                  <strong>
                    유니세프 및 다른 유엔기구들에게 그들의 활동분야에 한해 협약
                    이행에 관한 보고서 제출을 요청
                  </strong>
                  할 수 있다.
                </li>
              </ul>
            </dd>
          </DlBox>
          <ObjList className="flex">
            {termData.map((row, idnex) => (
              <li key={idnex}>
                <div className="img-wrap">
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </div>
                <div className="dec-wrap">
                  <Tit size="s4" color="sky">
                    {row.tit}
                  </Tit>
                  <p
                    className="dec"
                    dangerouslySetInnerHTML={{ __html: row.dec }}
                  />
                </div>
              </li>
            ))}
          </ObjList>
          <div className="btn-wrap">
            <ButtonMoreView
              mode="sky"
              href="/about-us/unicef/mission/convention-on-the-rights-of-the-child"
            >
              유엔아동권리협약 자세히 보기
            </ButtonMoreView>
          </div>
        </Container>
      </SectionTerm>

      <SectionPerformance className="by-sub-main-layout" id="hospital">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">한국의 협약 이행</Tit>
            </h2>
            <p className="header-dec">
              한국은 1991년 11월 20일 유엔아동권리협약을 비준했으며, 이에 따라
              한국 정부는 협약 이행에 관한 국가보고서를 작성해
              유엔아동권리위원회에 제출합니다. 국가보고서는 유엔아동권리위원회의
              지침에 따라,{` `}
              <strong>
                1) 협약 이행을 위한 일반조치, 2) 아동의 정의, 3) 일반원칙, 4)
                시민적 권리와 자유, 5) 아동에 대한 폭력, 6) 가정환경 및 대안
                양육, 7) 장애, 기초보건 및 복지, 8) 아동의 권리와 환경 9) 교육,
                여가 및 문화활동, 10) 특별보호 조치
              </strong>
              {` `}등 10개 주제로 나누어 작성됩니다. 유엔아동권리위원회는
              한국에서 제출한 국가보고서를 심의한 후, 한국의 협약 이행상황에
              대한 우려와 제안을 담은 최종견해(권고사항)를 한국 정부에
              전달합니다.
            </p>
          </SectionHeader>

          <div className="article-container">
            <article>
              <Card>
                <dt>
                  <Tit size="s3" color="sky">
                    아동권리협약 비준 (1991년 11월 20일)
                  </Tit>
                  <small>
                    : 국내법과 일치하지 않는다는 이유로 아래 3개 조항 유보
                  </small>
                </dt>
                <dd>
                  <ul className="blit-list black">
                    <li>
                      <strong
                        css={`
                          ${breakpoint(640)} {
                            display: block;
                          }
                        `}
                      >
                        제9조 제3항 아동의 부모 면접권
                      </strong>
                      {` `}(이혼 가정 자녀가 부모를 만날 권리가 법적으로
                      보장되지 않음)
                    </li>
                    <li>
                      <strong
                        css={`
                          ${breakpoint(640)} {
                            display: block;
                          }
                        `}
                      >
                        제21조 (가)항 정부 당국의 입양 허가
                      </strong>
                      {` `}(입양이 사실상 관계당국의 허가에 의해 이루어지지
                      않음)
                    </li>
                    <li>
                      <strong
                        css={`
                          ${breakpoint(640)} {
                            display: block;
                          }
                        `}
                      >
                        제40조 제2항(나)(5) 상소권 보장
                      </strong>
                      {` `}(비상계엄하의 재판이 단심제여서 아동의 권리를
                      보호하기 어려움)
                    </li>
                  </ul>
                </dd>
              </Card>
              <p className="tip">
                <span className="c-blue">* 2024년 현재</span>, 법 개정 등으로
                제40조 제2항(나)(5)만 유보된 상황
              </p>
            </article>

            <article>
              <Card>
                <dt>
                  <Tit size="s3" color="sky">
                    아동권리협약 3개 선택의정서 중 2개 비준 (2004년 9월 24일)
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list black t2">
                    <li>
                      <strong>비준한 아동권리협약 선택의정서</strong>
                      <p className="dec">
                        - 아동의 무력분쟁 관여에 관한 선택의정서 <br />-
                        아동매매·성매매를 통한 아동성착취·아동성착취물에 관한
                        선택의정서
                      </p>
                    </li>
                    <li>
                      <strong>비준 예정인 아동권리협약 선택의정서</strong>
                      <p className="dec">- 개인청원절차에 관한 선택의정서</p>
                    </li>
                  </ul>
                </dd>
              </Card>
            </article>

            <article>
              <table>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>국가보고서</th>
                    <th>민간보고서</th>
                    <th>유엔아동권리위원회 최종 견해 (권고사항)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="3">1 차</th>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=200703_CRC02.pdf&svrFileNm=200703_CRC02.pdf`}
                        className="link-item"
                      >
                        국문 통합본
                      </LinkSafe>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_1st_CRC_gov.pdf&svrFileNm=eng_1st_CRC_gov.pdf`}
                        className="link-item"
                      >
                        1994 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_1st_CRC_nongov.pdf&svrFileNm=eng_1st_CRC_nongov.pdf`}
                        className="link-item"
                      >
                        1995 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_1st_CRC_committee.pdf&svrFileNm=eng_1st_CRC_committee.pdf`}
                        className="link-item"
                      >
                        1996 영문
                      </LinkSafe>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colSpan="3">2 차</th>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=200703_CRC03.pdf&svrFileNm=200703_CRC03.pdf`}
                        className="link-item"
                      >
                        국문 통합본
                      </LinkSafe>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_2nd_CRC_gov.pdf&svrFileNm=eng_2nd_CRC_gov.pdf`}
                        className="link-item"
                      >
                        2000 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_2nd_CRC_nongov.pdf&svrFileNm=eng_2nd_CRC_nongov.pdf`}
                        className="link-item"
                      >
                        2002 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_2nd_CRC_committee.pdf&svrFileNm=eng_2nd_CRC_committee.pdf`}
                        className="link-item"
                      >
                        2003 영문
                      </LinkSafe>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colSpan="3">3·4 차</th>
                  </tr>
                  <tr>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_3rd_4th_CRC_gov.pdf&svrFileNm=kor_3rd_4th_CRC_gov.pdf`}
                        className="link-item"
                      >
                        2008 국문
                      </LinkSafe>
                    </td>
                    <td rowSpan="2">
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_eng_3rd_4th_CRC_nongov.pdf&svrFileNm=kor_eng_3rd_4th_CRC_nongov.pdf`}
                        className="link-item"
                      >
                        2010 국 · 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_3rd_4th_CRC_committee.pdf&svrFileNm=kor_3rd_4th_CRC_committee.pdf`}
                        className="link-item"
                      >
                        2011 국문
                      </LinkSafe>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_3rd_4th_CRC_gov.pdf&svrFileNm=eng_3rd_4th_CRC_gov.pdf`}
                        className="link-item"
                      >
                        2008 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_3rd_4th_CRC_committee.pdf&svrFileNm=eng_3rd_4th_CRC_committee.pdf`}
                        className="link-item"
                      >
                        2011 영문
                      </LinkSafe>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colSpan="3">5·6 차</th>
                  </tr>
                  <tr>
                    <td rowSpan="2">
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_eng_5rd_6th_CRC_gov.pdf&svrFileNm=kor_eng_5rd_6th_CRC_gov.pdf`}
                        className="link-item"
                      >
                        2017 국 · 영문
                      </LinkSafe>
                    </td>
                    <td rowSpan="2">
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_eng_5rd_6th_CRC_nongov.pdf&svrFileNm=kor_eng_5rd_6th_CRC_nongov.pdf`}
                        className="link-item"
                      >
                        2018 국 · 영문
                      </LinkSafe>
                    </td>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=kor_5th_6th_CRC_committee.pdf&svrFileNm=kor_5th_6th_CRC_committee.pdf`}
                        className="link-item"
                      >
                        2019 국문
                      </LinkSafe>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <LinkSafe
                        to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=eng_5th_6th_CRC_committee.pdf&svrFileNm=eng_5th_6th_CRC_committee.pdf`}
                        className="link-item"
                      >
                        2019 영문
                      </LinkSafe>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>
          </div>
        </Container>
      </SectionPerformance>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A022" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default CrcImplementation;
